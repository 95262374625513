import * as Types from '../../../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ShortPricelistsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ShortPricelistsQuery = { __typename?: 'Query', shortPricelists: Array<{ __typename?: 'ShortPricelist', shortPricelistId: string, macroFamily: string, preset: string, name: string, families: Array<{ __typename?: 'ShortPricelistFamily', name: string, prototypeIds: Array<string>, legend: Array<{ __typename?: 'LegendItem', code: string, value: string }> }> }> };

export type FamiliesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FamiliesQuery = { __typename?: 'Query', families: Array<{ __typename?: 'Family', value: string, label: string }> };

export type GetShortPricelistsPrototypesQueryVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  pagination: Types.Pagination;
  sort: Array<Types.Sort> | Types.Sort;
  filter: Array<Types.SearchFilter> | Types.SearchFilter;
}>;


export type GetShortPricelistsPrototypesQuery = { __typename?: 'Query', prototypes: { __typename?: 'SearchResult', nodes: { __typename?: 'NodeResult', count: number, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean }, edges: Array<{ __typename?: 'SearchNodeEdge', cursor: string, node: { __typename?: 'SearchNode', id: string, code: string, parent: string, parentCode: string, hierarchy: string, hierarchyCode: string, disabled: boolean, contentTypeGroupStatus?: Array<{ __typename?: 'ContentTypeGroupStatus', code: string, status: Types.CtgStatus }> | null, contents: Array<{ __typename?: 'NodeContentO', contentType: string, contentTypeGroup: string, type: string, inherited: boolean, value?: Array<{ __typename?: 'NodeContentValueO', code?: string | null, value: Array<any | null>, languageIsoCode: string, measure?: string | null }> | null }> } }> } } };

export type ShortPricelistPrototypesMutationVariables = Types.Exact<{
  shortPricelistId: Types.Scalars['String'];
  name: Types.Scalars['String'];
  prototypeIds: Array<Types.Scalars['String']> | Types.Scalars['String'];
  legend: Array<Types.LegendItemInput> | Types.LegendItemInput;
}>;


export type ShortPricelistPrototypesMutation = { __typename?: 'Mutation', saveShortPricelistFamily?: { __typename?: 'ObjectSchemaMutationResponse', code: string, success: boolean, message: string } | null };

export type ShortPricelistMutationVariables = Types.Exact<{
  macroFamily: Types.Scalars['String'];
  preset: Types.Scalars['String'];
  name: Types.Scalars['String'];
}>;


export type ShortPricelistMutation = { __typename?: 'Mutation', saveShortPricelist?: { __typename?: 'ObjectSchemaMutationResponse', code: string, success: boolean, message: string } | null };


export const ShortPricelistsDocument = gql`
    query ShortPricelists {
  shortPricelists {
    shortPricelistId
    macroFamily
    preset
    name
    families {
      name
      prototypeIds
      legend {
        code
        value
      }
    }
  }
}
    `;

/**
 * __useShortPricelistsQuery__
 *
 * To run a query within a React component, call `useShortPricelistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShortPricelistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShortPricelistsQuery({
 *   variables: {
 *   },
 * });
 */
export function useShortPricelistsQuery(baseOptions?: Apollo.QueryHookOptions<ShortPricelistsQuery, ShortPricelistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShortPricelistsQuery, ShortPricelistsQueryVariables>(ShortPricelistsDocument, options);
      }
export function useShortPricelistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShortPricelistsQuery, ShortPricelistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShortPricelistsQuery, ShortPricelistsQueryVariables>(ShortPricelistsDocument, options);
        }
export type ShortPricelistsQueryHookResult = ReturnType<typeof useShortPricelistsQuery>;
export type ShortPricelistsLazyQueryHookResult = ReturnType<typeof useShortPricelistsLazyQuery>;
export type ShortPricelistsQueryResult = Apollo.QueryResult<ShortPricelistsQuery, ShortPricelistsQueryVariables>;
export const FamiliesDocument = gql`
    query Families {
  families {
    value
    label
  }
}
    `;

/**
 * __useFamiliesQuery__
 *
 * To run a query within a React component, call `useFamiliesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFamiliesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFamiliesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFamiliesQuery(baseOptions?: Apollo.QueryHookOptions<FamiliesQuery, FamiliesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FamiliesQuery, FamiliesQueryVariables>(FamiliesDocument, options);
      }
export function useFamiliesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FamiliesQuery, FamiliesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FamiliesQuery, FamiliesQueryVariables>(FamiliesDocument, options);
        }
export type FamiliesQueryHookResult = ReturnType<typeof useFamiliesQuery>;
export type FamiliesLazyQueryHookResult = ReturnType<typeof useFamiliesLazyQuery>;
export type FamiliesQueryResult = Apollo.QueryResult<FamiliesQuery, FamiliesQueryVariables>;
export const GetShortPricelistsPrototypesDocument = gql`
    query GetShortPricelistsPrototypes($ids: [String!], $pagination: Pagination!, $sort: [Sort!]!, $filter: [SearchFilter!]!) {
  prototypes(ids: $ids, pagination: $pagination, sort: $sort, filter: $filter) {
    nodes {
      count
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          code
          parent
          parentCode
          hierarchy
          hierarchyCode
          disabled
          contentTypeGroupStatus {
            code
            status
          }
          contents {
            contentType
            contentTypeGroup
            type
            inherited
            value {
              code
              value
              languageIsoCode
              measure
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetShortPricelistsPrototypesQuery__
 *
 * To run a query within a React component, call `useGetShortPricelistsPrototypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShortPricelistsPrototypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShortPricelistsPrototypesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetShortPricelistsPrototypesQuery(baseOptions: Apollo.QueryHookOptions<GetShortPricelistsPrototypesQuery, GetShortPricelistsPrototypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShortPricelistsPrototypesQuery, GetShortPricelistsPrototypesQueryVariables>(GetShortPricelistsPrototypesDocument, options);
      }
export function useGetShortPricelistsPrototypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShortPricelistsPrototypesQuery, GetShortPricelistsPrototypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShortPricelistsPrototypesQuery, GetShortPricelistsPrototypesQueryVariables>(GetShortPricelistsPrototypesDocument, options);
        }
export type GetShortPricelistsPrototypesQueryHookResult = ReturnType<typeof useGetShortPricelistsPrototypesQuery>;
export type GetShortPricelistsPrototypesLazyQueryHookResult = ReturnType<typeof useGetShortPricelistsPrototypesLazyQuery>;
export type GetShortPricelistsPrototypesQueryResult = Apollo.QueryResult<GetShortPricelistsPrototypesQuery, GetShortPricelistsPrototypesQueryVariables>;
export const ShortPricelistPrototypesDocument = gql`
    mutation ShortPricelistPrototypes($shortPricelistId: String!, $name: String!, $prototypeIds: [String!]!, $legend: [LegendItemInput!]!) {
  saveShortPricelistFamily(
    shortPricelistId: $shortPricelistId
    name: $name
    prototypeIds: $prototypeIds
    legend: $legend
  ) {
    code
    success
    message
  }
}
    `;
export type ShortPricelistPrototypesMutationFn = Apollo.MutationFunction<ShortPricelistPrototypesMutation, ShortPricelistPrototypesMutationVariables>;

/**
 * __useShortPricelistPrototypesMutation__
 *
 * To run a mutation, you first call `useShortPricelistPrototypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShortPricelistPrototypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shortPricelistPrototypesMutation, { data, loading, error }] = useShortPricelistPrototypesMutation({
 *   variables: {
 *      shortPricelistId: // value for 'shortPricelistId'
 *      name: // value for 'name'
 *      prototypeIds: // value for 'prototypeIds'
 *      legend: // value for 'legend'
 *   },
 * });
 */
export function useShortPricelistPrototypesMutation(baseOptions?: Apollo.MutationHookOptions<ShortPricelistPrototypesMutation, ShortPricelistPrototypesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShortPricelistPrototypesMutation, ShortPricelistPrototypesMutationVariables>(ShortPricelistPrototypesDocument, options);
      }
export type ShortPricelistPrototypesMutationHookResult = ReturnType<typeof useShortPricelistPrototypesMutation>;
export type ShortPricelistPrototypesMutationResult = Apollo.MutationResult<ShortPricelistPrototypesMutation>;
export type ShortPricelistPrototypesMutationOptions = Apollo.BaseMutationOptions<ShortPricelistPrototypesMutation, ShortPricelistPrototypesMutationVariables>;
export const ShortPricelistDocument = gql`
    mutation ShortPricelist($macroFamily: String!, $preset: String!, $name: String!) {
  saveShortPricelist(macroFamily: $macroFamily, preset: $preset, name: $name) {
    code
    success
    message
  }
}
    `;
export type ShortPricelistMutationFn = Apollo.MutationFunction<ShortPricelistMutation, ShortPricelistMutationVariables>;

/**
 * __useShortPricelistMutation__
 *
 * To run a mutation, you first call `useShortPricelistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShortPricelistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shortPricelistMutation, { data, loading, error }] = useShortPricelistMutation({
 *   variables: {
 *      macroFamily: // value for 'macroFamily'
 *      preset: // value for 'preset'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useShortPricelistMutation(baseOptions?: Apollo.MutationHookOptions<ShortPricelistMutation, ShortPricelistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShortPricelistMutation, ShortPricelistMutationVariables>(ShortPricelistDocument, options);
      }
export type ShortPricelistMutationHookResult = ReturnType<typeof useShortPricelistMutation>;
export type ShortPricelistMutationResult = Apollo.MutationResult<ShortPricelistMutation>;
export type ShortPricelistMutationOptions = Apollo.BaseMutationOptions<ShortPricelistMutation, ShortPricelistMutationVariables>;